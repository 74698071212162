import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ButtonContained from "../ButtonContained/ButtonContained";

import "./styles.scss";

function BlueBanner() {
  const { t } = useTranslation();
  return (
    <div className="blueBanner" id="bluebannerjs">
      <p className="blueBanner__text">
        {t("baltic.bluebanner.join")}{" "}
        <span className="blueBanner__text__purple" title="bitcoin & freedom">
          {t("baltic.bluebanner.bitcoinfreedom")}
        </span>{" "}
        {t("baltic.bluebanner.content")}
      </p>
      <div className="blueBanner__ticketsBox">
        <ButtonContained>
          <Link
            to="/buy-tickets"
            className="blueBanner__button__link"
            alt="Buy Tickets"
          >
            {t("baltic.bluebanner.buytickets")}
          </Link>
        </ButtonContained>
      </div>
    </div>
  );
}

export default BlueBanner;
