import React from "react";
import { useTranslation } from "react-i18next";

import ButtonContained from "../ButtonContained/ButtonContained";
import { BuyTicketsButton } from "../Navigation/Navigation";
import { useIsMobile } from "../../hooks/useMediaQuery";
import balticHoneybadgerLogo from "../../assets/images/Honeybadger_desktop.webp";
import balticHoneybadgerLogoMobile from "../../assets/images/honeybadger_mobile.webp";

import "./header.scss";

function Header({ title }) {
  const { t } = useTranslation();
  const mobile = useIsMobile();

  return (
    <div className="header">
      <div className="header__heading">
        {mobile ? (
          <h1 className="header__subtitle">
            {t("baltic.honey.themostogbitcoin")} <br /> conference in the world
          </h1>
        ) : (
          <h1 className="header__subtitle">
            {t("baltic.honey.themostogbitcoinconference")}
          </h1>
        )}
        <h2 className="header__title" title="Baltic HoneyBadger 2024">
          {title}
        </h2>
      </div>
      <img
        alt="Baltic Honeybadger bitcoin conference 2024"
        src={mobile ? balticHoneybadgerLogoMobile : balticHoneybadgerLogo}
        className="header__image"
      />
      <div className="header__actions">
        <ButtonContained>
          <BuyTicketsButton />
        </ButtonContained>
      </div>
    </div>
  );
}

export default Header;
