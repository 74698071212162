import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Navigation from "./components/Navigation/Navigation";
import Main from "./pages/Main";
import Agenda from "./pages/Agenda/Agenda";
import Speakers from "./pages/Speakers/Speakers";
import BitcoinWeek from "./pages/BitcoinWeek/BitcoinWeek";
import BuyTickets from "./pages/BuyTickets/BuyTickets";
import OfferForSponsors from "./pages/OfferForSponsors/OfferForSponsors";
import Footer from "./components/Footer/Footer";
import ConferenceTickets from "./pages/ConferenceTickets/ConferenceTickets";

import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import TermsConditions from "./pages/Terms&Conditions/TermsConditions";
import Location from "./pages/Location/Location";
import Nostriga from "./pages/Nostriga/Nostriga";

function App() {
  return (
    <Router>
      <Navigation />
      <ScrollToTop>
        <Routes>
          <Route exact path="/" element={<Main />} />
          <Route exact path="/agenda" element={<Agenda />} />
          <Route exact path="/speakers" element={<Speakers />} />
          <Route exact path="/riga-bitcoin-week" element={<BitcoinWeek />} />
          <Route exact path="/nostriga" element={<Nostriga />} />
          <Route exact path="/register" element={<BuyTickets />} />
          <Route
            exact
            path="/offer-for-sponsors"
            element={<OfferForSponsors />}
          />
          <Route exact path="/sponsorship" element={<OfferForSponsors />} />
          <Route exact path="/buy-tickets" element={<ConferenceTickets />} />
          <Route exact path="/terms-conditions" element={<TermsConditions />} />
          <Route exact path="/location" element={<Location />} />
        </Routes>
      </ScrollToTop>
      <Footer />
    </Router>
  );
}
export default App;
