import React from "react";
import { Helmet } from "react-helmet";

import balticHoneybadgerOstrich from "./background.png";
import "./style.scss";
import { useTranslation } from "react-i18next";

function Nostriga() {
  const { t } = useTranslation();
  return (
    <section className="nostriga">
      <Helmet>
        <title>{t("baltic.honey.nostrigaUnconferenceTitle")}</title>
        <meta
          name="description"
          content="Nostr conference 2024, held in Riga, Latvia"
        />
      </Helmet>
      <div className="nostriga__container">
        <h1 style={{ visbility: "hidden", fontSize: "0" }}>
          {t("baltic.honey.unconferenceTicketTitle")}
        </h1>
        <h2 className="nostriga__title" title="NOSTRIGA UNconference ticket">
          {t("baltic.honey.unconferenceTicketTitle")}
        </h2>
        <p className="nostriga__subtitle">
          {t("baltic.honey.nostrigaUnconferenceDescription")}
        </p>
        <div className="nostriga__card">
          <div className="card__header">
            <div className="card__about-price">
              <span className="card__price">2 day nostriga ticket</span>
              <ul className="card__list">
                {/* <li className="card__elem">Food and drinks</li> */}
                <li className="card__elem">
                  {t("baltic.honey.august22And23")}
                </li>
                <li className="card__elem">
                  {t("baltic.honey.inPersonAdmissionTicket")}
                </li>
                <li className="card__elem">
                  <span>
                    Learn more about{" "}
                    <a className="card__elem__link" href="https://nostr.world/">
                      NOSTRIGA
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="card__buttons">
            <a
              href="https://tally.so/r/wbdMlL"
              target="_blank"
              rel="noopener noreferrer"
              className="button"
            >
              {t("baltic.honey.getYourFreeTicket")}
            </a>
          </div>
        </div>
      </div>
      <img
        alt="NOSTRIGA UNconference ticket"
        src={balticHoneybadgerOstrich}
        className="nostriga__image"
      />
    </section>
  );
}

export default Nostriga;
