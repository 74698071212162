import React, {useState} from "react";
import clsx from "clsx";
import gsap from "gsap";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {useScroll} from "../../hooks/useScroll";
import { useMediaQuery } from "../../hooks/useMediaQuery";
import ButtonContained from "../ButtonContained/ButtonContained";
import closeMenuUrl from "./../../assets/images/close.svg";

import "./style.scss";

const links = [
  {
    title: "Speakers",
    to: "/speakers",
  },
  {
    title: "Agenda",
    to: "/agenda",
  },
  {
    title: "Riga Bitcoin Week",
    to: "/riga-bitcoin-week",
  },
  {
    title: "Nostriga",
    to: "/nostriga",
  },
  {
    title: "Location",
    to: "/location",
  },
  {
    title: "Cultural Guide",
    to: "https://drive.proton.me/urls/H6GNJ381VM#u91bTQVMs07F",
    target: true,
  },
];

export const BuyTicketsButton = ({ onClick }) => {
  const { t } = useTranslation();
  return (
    <Link onClick={onClick} to="/buy-tickets" alt="Buy Tickets">
      {t("baltic.bluebanner.buytickets")}
    </Link>
  );
};

const Navigation = React.memo(function Navigation() {
  const { t } = useTranslation();

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [showHeader, setShowHeader] = useState(true);
  let isTablet = useMediaQuery(`(max-width: 1024px)`);

  const onShowMenu = () => {
    if (!isMenuOpen) {
      gsap.to("#menu", { duration: 0.1, left: 0 });
      setMenuOpen(!isMenuOpen);
    } else if (isMenuOpen) {
      gsap.to("#menu", { duration: 0.1, left: "-100%" });
      setMenuOpen(!isMenuOpen);
    }
  };

  const onMenuClose = () => {
    gsap.to("#menu", { duration: 0.1, left: "-100%" });
    setMenuOpen(false);
  };

  useScroll((currentScrollY, prevScrollY) => {
    if (currentScrollY < prevScrollY) {
      setShowHeader(true);
    } else {
      setShowHeader(false);
    }
  });

  console.log('Navigation.render')

  return (
      <div
          className={clsx("nav", {
            stickyNav: showHeader,
          })}
      >
        <div className="nav__container page-container">
          <div className="nav__left">
            <Link to="/" className="nav__logo" alt="Main page">
              {isTablet ? (
                  <span className="nav__logo__title">
                  {t("baltic.honey.baltichoneybadger")}
                </span>
              ) : (
                  <span className="nav__logo-image" title="Baltic Honeybadger"/>
              )}
            </Link>
            <div className="nav__date">
              {t("baltic.honey.dateandlocation")} <br/>{" "}
              {t("baltic.honey.dateandlocation2")}
            </div>
          </div>

          <div className="header__menu menu nav__right">
            <div onClick={onShowMenu} className="menu__icon">
              <span/>
            </div>
            <nav onClick={onMenuClose} id="menu" className="menu__body">
              <div className="menu__body__header">
                {isMenuOpen && (
                    <h1 className="menu__body__title">
                      {t("baltic.honey.baltichoneybadger")}
                    </h1>
                )}
                <img
                    src={closeMenuUrl}
                    alt="close menu button"
                    className="menu__close"
                />
              </div>
              <ul className="menu__list">
                {links.map((link) =>
                    link.target ? (
                        <a
                            key={link.to}
                            onClick={onMenuClose}
                            href={link.to}
                            target={"_blank"}
                            className="nav__link nav__link_button"
                            alt={link.title}
                        >
                          {link.title}
                        </a>
                    ) : (
                        <Link
                            key={link.to}
                            onClick={onMenuClose}
                            to={link.to}
                            className="nav__link nav__link_button"
                            alt={link.title}
                        >
                          {link.title}
                        </Link>
                    )
                )}
                <ButtonContained onClick={onMenuClose}>
                  <BuyTicketsButton/>
                </ButtonContained>
              </ul>
            </nav>
          </div>
        </div>
      </div>
  );
})

export default Navigation;
